/* You can add global styles to this file, and also import other style files */

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
    @tailwind base;
}

@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}

@font-face {
    font-family: "RigSans";
    font-weight: 400;
    src: url("/fonts/RigRegular.otf") format("opentype");
}

@font-face {
    font-family: "RigSansBold";
    font-weight: 700;
    src: url("/fonts/RigBold.otf") format("opentype");
}

@font-face {
    font-family: "RigSansBoldItalic";
    src: url("/fonts/RigBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "RigSansLightItalic";
    src: url("/fonts/RigLightItalic.otf") format("opentype");
}

@font-face {
    font-family: "RigSansMedium";
    src: url("/fonts/RigMedium.otf") format("opentype");
}

* {
    // font-family: RigSans;
    @apply font-rig_sans;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 27px;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 27px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    border-color: none;
    box-shadow: 0 0 0 0.1rem #0078a9;
}

.p-inputtext:enabled:hover {
    @apply border-neutral300
}

.p-inputswitch {
    width: 1.5rem;
    height: 0.923rem;
    border-radius: 1.141rem;

    &.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }
}

.p-inputswitch-slider {

    &::before {
        width: 0.807rem;
        height: 0.807rem;
        left: 0;
        margin-top: -0.425rem;
    }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    @apply bg-primary500;

    &:before {
        transform: translateX(0.573rem);
    }

}

.maxCap {
    max-width: 1216px;
    margin: 0 auto;
}

body {
    background-color: #f3f4f6;
}

p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext {
    box-shadow: 0 0 0 0.1rem #e24c4c;
    border-width: 0;
}

app-location-picker {
    .p-inputtext {
        width: 100%;
        transition: none;
        @apply py-2.5 px-3 rounded-2xl;
    }

    .p-autocomplete {
        width: 100%;
    }
}

app-fleet-management,
app-resource-management {
    .p-selectbutton .p-button.p-highlight {
        @apply bg-primary500 border-primary500 text-white;
    }

    .p-button {
        padding-block: .3rem;
        outline: 0;
        border: 0;
        border-radius: 16px;
        color: black;
    }

    .p-button-label {
        font-weight: 400;

    }

    .p-button:focus {
        box-shadow: 0 0 0 0;
    }

    .p-dialog .p-dialog-content {
        padding-bottom: 1rem;
    }
}

app-select p-dropdown {

    // &.ng-invalid.ng-touched {
    //     border: 1px solid red;
    // }
    .p-disabled,
    .p-disabled * {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.2;
    }

    &.ng-touched.ng-invalid>.p-dropdown {
        border-color: #e24c4c;

        &:focus-within {
            outline-color: yellow;
            border-color: #e24c4c;
            box-shadow: 0 0 0 0.1rem #e24c4c;
        }
    }

    & .p-dropdown {
        width: 100%;
        padding: 0.625rem 0.75rem;
        border: 1px solid rgb(209 213 219);
        border-radius: 1rem;

        & .p-dropdown-trigger {
            padding-left: 2rem;
        }
    }

    & .p-inputtext {
        padding: 0;
        color: #0d0d0c;
        font-size: 14px;
    }
}

app-rate-con {}

.p-fileupload-content .p-progressbar,
.p-message-wrapper {
    display: none;
}

app-file-upload {
    .p-fileupload .p-fileupload-content {
        padding: 0;
    }
}

.p-multiselect:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.1rem #0078a9;
}

.p-fileupload {
    .p-fileupload-buttonbar {
        display: none;
    }

    .p-fileupload-content {
        @apply border rounded-3xl;
    }
}

anglerighticon {
    svg {
        width: 20px;
        height: 20px;
        color: #0d0d0c;
    }
}

.p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #0d0d0c;
}

.p-tieredmenu .p-menuitem-active>p-tieredmenusub>.p-submenu-list {
    margin-inline: -0.5rem;
    border-radius: 16px;
}

.p-tieredmenu,
.p-menu {
    border-radius: 16px;

    & .p-menuitem {
        padding-inline: 0.375rem;

        &.p-highlight>.p-menuitem-content {
            @apply bg-neutral200 rounded-2xl;
        }

        &>.p-menuitem-content {
            @apply rounded-2xl;

            &:hover {
                @apply bg-neutral200;
            }

            & .p-menuitem-link {
                @apply flex gap-4 text-sm;
            }
        }

        &.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
            color: #0d0d0c;
        }
    }
}

app-date-picker .p-button.p-button-icon-only {
    width: 1rem;
}

app-select p-multiSelect {
    & .p-inputwrapper {
        width: 100%;
        padding: 0.375rem 0.75rem;
        border: 1px solid rgb(209 213 219);
        border-radius: 1rem;
    }

    & .p-multiselect-trigger {
        padding-left: 2rem;
    }

    & .p-multiselect-label {
        padding: 0;
    }

    & .p-multiselect-token-label {
        @apply text-sm;
    }

    & .p-multiselect-chip .p-multiselect-token {
        @apply py-1 pl-3;
    }

    .p-multiselect-panel {
        @apply border border-solid border-neutral200 rounded-2xl;

        .p-multiselect-items {
            padding: 0.25rem 0.5rem;

            .p-multiselect-item {
                border-radius: 16px;

            }
        }

        .p-checkbox .p-checkbox-box.p-highlight {
            @apply bg-primary500 border-primary500;
        }

        margin-block: 0.3rem;
        // .p-multiselect-header {
        //   display: none;
        // }
    }

    .p-multiselect-panel .p-multiselect-filter-container {
        .p-multiselect-filter {
            border-radius: 50px !important;
            border: 1px solid #ccc !important;
            padding-left: 10px !important;
        }

        /* Hide the search icon */
        .p-multiselect-filter-icon {
            display: none !important;
        }
    }
}

.skeleton-row {
    background: #f0f0f0;
    height: 2rem;
    width: 100%;

    border-radius: 5rem;
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

.custom-tooltip {
    background-color: #0078A9;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    pointer-events: none;
}

.custom-tooltip::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 20px;
    border-style: solid;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #0078A9;
    border-color: #0078A9 transparent transparent transparent;
}

.custom-tooltip-bottom {
    background-color: #0078A9;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    max-width: 200px;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
    pointer-events: none;
}

.custom-tooltip-bottom::before {
    content: '';
    position: absolute;
    left: 20px;
    border-style: solid;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #0078A9;
    transform: rotate(180deg);
    border-color: #0078A9 transparent transparent transparent;
    z-index: -1;
}

.p-fileupload .p-fileupload-content {
    padding: 0 !important;
}

.p-dialog {
    border-radius: 1rem;
}

.p-dialog .p-dialog-header {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.p-tabmenu .p-tabmenu-nav {
    border: 0;
    background-color: white;
    border-radius: 80px;
    padding: 3.8px 0;
}




::ng-deep .p-sidebar-content {
    overflow: hidden;

}

.p-sidebar {
    background: #F3F4F6;
}




.p-dialog .p-dialog-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 1.5rem 1.5rem 0 1.5rem;

}

.p-dialog .p-dialog-content {
    background: #ffffff;
    color: #4b5563;
    padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.popover {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    max-width: 300px;
    transition: opacity 0.3s ease;
    opacity: 1;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.p-overlaypanel {
    border-radius: 16px;
}
